import { lazy, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { getCookie } from "./utils/cookies";
import { LOGIN_COOKIES } from "./config";
import { listenForAuthMessages, AUTH_CHANNEL } from "./utils/logout";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setLoggenInpanelName } from "./redux/general/general-action";

const AppRoutes = lazy(() => import("./routes/routes"));

function App() {
  const [loading, setLoading] = useState<boolean>(false);
  const isAuthenticated = useSelector(
    (state: any) => state.general.isAuthenticated
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogout = () => {
      // Reload the current tab on logout message
      window.location.reload();
    };

    // Start listening for logout messages
    listenForAuthMessages(handleLogout);

    // Cleanup the listener when the component unmounts
    return () => {
      const channel = new BroadcastChannel(AUTH_CHANNEL);
      channel.close();
    };
  }, []);

  useEffect(() => {
    const authentication = getCookie(LOGIN_COOKIES.REFRESH_TOKEN);
    let booleanValue = authentication ? true : false;
    handleSetIsAuthenticated(booleanValue);
    setLoading(true);
  }, []);

  const handleSetIsAuthenticated = (flag: any) => {
    dispatch(setAuth(flag));
    if(flag){
      const panelName = getCookie(LOGIN_COOKIES.LOGGEDIN_PANEL);
      dispatch(setLoggenInpanelName(panelName))
    }else{
      dispatch(setLoggenInpanelName(""))
    }
  };

  return (
    <BrowserRouter>
      {loading ? (
        <AppRoutes
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={handleSetIsAuthenticated}
        />
      ) : (
        <></>
      )}
    </BrowserRouter>
  );
}

export default App;
