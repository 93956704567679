import {
  UPDATE_USER_PICTURE,
  CHANGE_USER_PASSWORD,
  ADD_USER_PROFILE,
  PROFILE_LOADER,
  PROFILE_FAILURE,
} from "./profile-constant";

import { handleLoading, handleFailure } from "../../container/harmoni-panel/store/utils/reducerUtils";

// Initial state
const initialState = {
  profile: {},
  loading: false,
  error: null,
  redirectTo: false,
};

export const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_LOADER:
      return handleLoading(state, action);

    case PROFILE_FAILURE:
      return handleFailure(state, action);

    case CHANGE_USER_PASSWORD:
      return { ...state, loading: false, error: null };
    case ADD_USER_PROFILE:
      return { ...state, loading: false, error: null, profile: action.payload };

    case UPDATE_USER_PICTURE:
      var existingProfileInfo = { ...state.profile };
      existingProfileInfo.profile_picture = action.payload.profile_picture;
      return {
        ...state,
        loading: false,
        error: null,
        profile: existingProfileInfo,
      };

    default:
      return state;
  }
};
