// action.js
import { SAVE_NAME,SET_AUTH,SET_LOGGEDIN_PANEL } from './constants';

export const saveName = (name) => ({
  type: SAVE_NAME,
  payload: name
});

export const setAuth = (flag) => ({
  type: SET_AUTH,
  payload: flag
});

export const setLoggenInpanelName = (name) => ({
  type: SET_LOGGEDIN_PANEL,
  payload: name
});
