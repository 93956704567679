export const SET_SUBSCRIPTION_PAGE_DATA = 'SET_SUBSCRIPTION_PAGE_DATA';
export const SET_SUBSCRIPTION_TOTAL_RECORDS = 'SET_SUBSCRIPTION_TOTAL_RECORDS';
export const SET_SUBSCRIPTION_PAGE = 'SET_SUBSCRIPTION_PAGE';
export const EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION';
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION= 'DELETE_SUBSCRIPTION';
export const SUBSCRIPTION_LOADING = 'SUBSCRIPTION_LOADING';
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE';
export const GET_SUBSCRIPTION= 'GET_SUBSCRIPTION';
export const SET_SUBSCRIPTION_REDIRECT_URL = 'SET_SUBSCRIPTION_REDIRECT_URL';


export const SUBSCRIPTION_PROGRESS_TYPE={
    GET_SUBSCRIPTION_LIST:"GET_SUBSCRIPTION_LIST",
    ADD_SUBSCRIPTION_PROGRESS:"ADD_SUBSCRIPTION_PROGRESS",
    EDIT_SUBSCRIPTION_PROGRESS:"EDIT_SUBSCRIPTION_PROGRESS",
    DELETE_SUBSCRIPTION_PROGRESS:"DELETE_SUBSCRIPTION_PROGRESS"
}

