import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { thunk } from "redux-thunk";
import { generalReducer } from "./general/general-reducers";
import layoutReducer from "../layout/sidebar/sidebar-store/reducers"; // Import the new reducer
import { HARMONI_ROOT_REDUCER } from "../container/harmoni-panel/store/harmoni-root-reducer";
import { broadcastMiddleware,setupBroadcastListener } from './broadcast/broadcastMiddleware';
import { RESET_STORE } from "./general/constants";
import { ProfileReducer } from "./harmoni-profile/profile-reducer";

const appReducer = combineReducers({
  general: generalReducer,
  layout: layoutReducer,
  profile:ProfileReducer,
  ...HARMONI_ROOT_REDUCER
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;  // Reset the entire state to undefined, triggering the initial state
  }
  return appReducer(state, action);
};


// Define composeEnhancers
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose);

// Create the store
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk,broadcastMiddleware)));

setupBroadcastListener(store);

export default store;
