// src/broadcast/broadcastMiddleware.js
const broadcast = new BroadcastChannel('app-broadcast-sync');

// Function to broadcast an action
export const broadcastAction = (action) => {
  broadcast.postMessage(action);
};

// Middleware to automatically broadcast actions with the `broadcast` flag
export const broadcastMiddleware = (store) => (next) => (action) => {
  // Check if the action has the broadcast flag set to true, and if it is not a broadcasted action
  if (action.broadcast && !action._fromBroadcast) {
    // Remove the `broadcast` flag before broadcasting the action to avoid infinite loops
    const broadcastAction = { ...action, _fromBroadcast: true };
    delete broadcastAction.broadcast; // Remove broadcast flag for the dispatched version
    broadcast.postMessage(broadcastAction);
  }

  return next(action); // Continue to the next middleware or reducer
};

// Set up a listener for incoming broadcast actions
export const setupBroadcastListener = (store) => {
  broadcast.onmessage = (event) => {
    const action = event.data;

    // Make sure the received action has the `_fromBroadcast` flag so that it doesn't trigger API calls
    if (action._fromBroadcast) {
      store.dispatch(action);
    }
  };
};
