export const SET_PAGE_DATA = 'SET_PAGE_DATA';
export const SET_TOTAL_RECORDS = 'SET_TOTAL_RECORDS';
export const SET_PAGE = 'SET_PAGE';
export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const DELETE_EMPLOYEES = 'DELETE_EMPLOYEES';
export const EMPLOYEES_LOADING = 'EMPLOYEES_LOADING';
export const EMPLOYEES_FAILURE = 'EMPLOYEES_FAILURE';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';


export const EMPLOYEE_PROGRESS_TYPE={
    GET_EMPLOYEE_LIST:"GET_EMPLOYEE_LIST",
    ADD_EMPLOYEE_PROGRESS:"ADD_EMPLOYEE_PROGRESS",
    EDIT_EMPLOYEE_PROGRESS:"EDIT_EMPLOYEE_PROGRESS",
    DELETE_EMPLOYEE_PROGRESS:"DELETE_EMPLOYEE_PROGRESS"
}

