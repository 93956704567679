import {
  SET_SUBSCRIPTION_PAGE_DATA,
  SET_SUBSCRIPTION_TOTAL_RECORDS,
  SET_SUBSCRIPTION_PAGE,
  EDIT_SUBSCRIPTION,
  ADD_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  SUBSCRIPTION_LOADING,
  SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTION,
  SET_SUBSCRIPTION_REDIRECT_URL
} from "./subscription-constant";

import {
  handleLoading,
  handleFailure,
  handleSetRedirectUrl,
  handleGetData,
  handleSetTotalRecords,
  handleSetPage,
  handleEditItem,
  handleAddItem,
  handleDeleteItems,
} from "../utils/reducerUtils";

// Initial state
const initialState = {
  subscriptionList: {},
  totalRecords: 0,
  totalPages: 0,
  currentPage: 1,
  loading: false,
  error: null,
  redirectTo: false,
};

export const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_LOADING:
      return handleLoading(state, action);

    case SUBSCRIPTION_FAILURE:
      return handleFailure(state, action);

    case SET_SUBSCRIPTION_REDIRECT_URL:
      return handleSetRedirectUrl(state, action);

    case GET_SUBSCRIPTION:
      return handleGetData(state, action, "subscriptionList");

    case SET_SUBSCRIPTION_TOTAL_RECORDS:
      return handleSetTotalRecords(state, action);

    case SET_SUBSCRIPTION_PAGE:
      return handleSetPage(state, action);

    case ADD_SUBSCRIPTION:
      return handleAddItem(state, action, "subscriptionList", "subscription_id");

    case EDIT_SUBSCRIPTION:
      return handleEditItem(state, action, "subscriptionList", "subscription_id");

    case DELETE_SUBSCRIPTION:
      return handleDeleteItems(state, action, "subscriptionList", "subscription_id");

    default:
      return state;
  }
};
