export const SET_SERVICES_PAGE_DATA = 'SET_SERVICES_PAGE_DATA';
export const SET_SERVICES_TOTAL_RECORDS = 'SET_SERVICES_TOTAL_RECORDS';
export const SET_SERVICES_PAGE = 'SET_SERVICES_PAGE';
export const EDIT_SERVICES = 'EDIT_SERVICES';
export const ADD_SERVICES = 'ADD_SERVICES';
export const DELETE_SERVICES= 'DELETE_SERVICES';
export const SERVICES_LOADING = 'SERVICES_LOADING';
export const SERVICES_FAILURE = 'SERVICES_FAILURE';
export const GET_SERVICES= 'GET_SERVICES';
export const SET_SERVICES_REDIRECT_URL = 'SET_SERVICES_REDIRECT_URL';


export const SERVICES_PROGRESS_TYPE={
    GET_SERVICES_LIST:"GET_SERVICES_LIST",
    ADD_SERVICES_PROGRESS:"ADD_SERVICES_PROGRESS",
    EDIT_SERVICES_PROGRESS:"EDIT_SERVICES_PROGRESS",
    DELETE_SERVICES_PROGRESS:"DELETE_SERVICES_PROGRESS"
}

