export const SET_AMENITIES_PAGE_DATA = 'SET_AMENITIES_PAGE_DATA';
export const SET_AMENITIES_TOTAL_RECORDS = 'SET_AMENITIES_TOTAL_RECORDS';
export const SET_AMENITIES_PAGE = 'SET_AMENITIES_PAGE';
export const EDIT_AMENITIES = 'EDIT_AMENITIES';
export const ADD_AMENITIES = 'ADD_AMENITIES';
export const DELETE_AMENITIES= 'DELETE_AMENITIES';
export const AMENITIES_LOADING = 'AMENITIES_LOADING';
export const AMENITIES_FAILURE = 'AMENITIES_FAILURE';
export const GET_AMENITIES= 'GET_AMENITIES';
export const SET_AMENITIES_REDIRECT_URL = 'SET_AMENITIES_REDIRECT_URL';


export const AMENITIES_PROGRESS_TYPE={
    GET_AMENITIES_LIST:"GET_AMENITIES_LIST",
    ADD_AMENITIES_PROGRESS:"ADD_AMENITIES_PROGRESS",
    EDIT_AMENITIES_PROGRESS:"EDIT_AMENITIES_PROGRESS",
    DELETE_AMENITIES_PROGRESS:"DELETE_AMENITIES_PROGRESS"
}

