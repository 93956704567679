import { employeeReducer } from "./employee/employee-reducer";
import { serviceReducer } from "./services/services-reducer";
import {amenitiesReducer} from './amenities/amenities-reducer'
import {subscriptionReducer} from "./subscription/subscription-reducer"



export const HARMONI_ROOT_REDUCER={
    employees: employeeReducer,
    services:serviceReducer,
    amenities:amenitiesReducer,
    subscription:subscriptionReducer
}