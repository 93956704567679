// utils/reducerUtils.js

import { PAGINATION_PER_PAGE } from "../../../../config";

// Calculate total pages based on total records and pagination limit
export const calculateTotalPages = totalRecords => {
  return Math.ceil(totalRecords / PAGINATION_PER_PAGE);
};

// Handle loading state
export const handleLoading = (state, action) => ({
  ...state,
  loading: action.payload,
  error: null,
});

// Handle failure state
export const handleFailure = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

// Handle setting redirect URL
export const handleSetRedirectUrl = (state, action) => ({
  ...state,
  redirectTo: action.payload,
});

// Handle getting paginated data (GET_AMENITIES/GET_EMPLOYEES)
export const handleGetData = (state, action, listKey) => {
  const { page, data } = action.payload;
  return {
    ...state,
    loading: false,
    error: null,
    [listKey]: {
      ...state[listKey],
      [page]: data, // Add/update the data for the current page
    },
  };
};

// Handle setting total records and calculating total pages
export const handleSetTotalRecords = (state, action) => ({
  ...state,
  totalRecords: action.payload,
  totalPages: calculateTotalPages(action.payload),
});

// Handle setting the current page
export const handleSetPage = (state, action) => ({
  ...state,
  currentPage: action.payload,
});

// Handle editing an item in the list
export const handleEditItem = (state, action, listKey, itemKey) => ({
  ...state,
  loading: false,
  error: null,
  [listKey]: Object.fromEntries(
    Object.entries(state[listKey]).map(([page, items]) => [
      page,
      items.map(item =>
        item[itemKey] === action.payload[itemKey] ? action.payload : item
      ),
    ])
  ),
});

// Handle adding a new item
export const handleAddItem = (state, action, listKey, itemKey) => {
  const newItem = action.payload;
  const newList = { ...state[listKey] };
  let totalRecordsAfterAdd = state.totalRecords + 1;

  let pageToAdd = 1;

  if (!newList[pageToAdd]) {
    newList[pageToAdd] = [];
  }
  newList[pageToAdd].unshift(newItem);

  let currentPage = pageToAdd;
  while (newList[currentPage] && newList[currentPage].length > PAGINATION_PER_PAGE) {
    const extraItem = newList[currentPage].pop();
    currentPage++;
    if (!newList[currentPage]) {
      newList[currentPage] = [];
    }
    newList[currentPage].unshift(extraItem);
  }

  return {
    ...state,
    loading: false,
    error: null,
    [listKey]: newList,
    totalRecords: totalRecordsAfterAdd,
    totalPages: calculateTotalPages(totalRecordsAfterAdd),
  };
};

// Handle deleting items
export const handleDeleteItems = (state, action, listKey, itemKey) => {
  const idsToDelete = new Set(action.payload);
  if (idsToDelete.size === 0) return state; // No items to delete

  const newList = { ...state[listKey] };

  // Filter out deleted items from each page
  Object.keys(newList).forEach(page => {
    newList[page] = newList[page].filter(item => !idsToDelete.has(item[itemKey]));
  });

  // Rebuild the list
  const allItems = [];
  Object.values(newList).forEach(page => allItems.push(...page));

  const totalRecordsAfterDelete = allItems.length;
  const totalPagesAfterDelete = calculateTotalPages(totalRecordsAfterDelete);

  const newListStructure = {};
  for (let i = 0; i < totalPagesAfterDelete; i++) {
    const startIndex = i * PAGINATION_PER_PAGE;
    const endIndex = Math.min((i + 1) * PAGINATION_PER_PAGE, allItems.length);
    if (startIndex < endIndex) {
      newListStructure[i + 1] = allItems.slice(startIndex, endIndex);
    }
  }

  const newCurrentPage = Math.max(1, Math.min(state.currentPage, totalPagesAfterDelete));

  return {
    ...state,
    loading: false,
    error: null,
    [listKey]: newListStructure,
    totalRecords: totalRecordsAfterDelete,
    totalPages: totalPagesAfterDelete,
    currentPage: newCurrentPage,
  };
};
