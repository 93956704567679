// reducer.js
import { SAVE_NAME, SET_AUTH,SET_LOGGEDIN_PANEL } from "./constants";

// Initial state
const initialState = {
  name: "",
  isAuthenticated: false,
  loggedInPanel:""
};

// Reducer function
export const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload,
      };

      case SET_LOGGEDIN_PANEL:
        return {
          ...state,
          loggedInPanel: action.payload,
        };
    default:
      return state;
  }
};
