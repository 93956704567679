import {
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  DELETE_EMPLOYEES,
  SET_PAGE_DATA,
  SET_TOTAL_RECORDS,
  SET_PAGE,
  EMPLOYEES_LOADING,
  EMPLOYEES_FAILURE,
  GET_EMPLOYEES,
  SET_REDIRECT_URL,
} from "./employee-constant";
import {
  handleLoading,
  handleFailure,
  handleSetRedirectUrl,
  handleGetData,
  handleSetTotalRecords,
  handleSetPage,
  handleEditItem,
  handleAddItem,
  handleDeleteItems,
} from "../utils/reducerUtils";

// Initial state
const initialState = {
  employeeList: {},
  totalRecords: 0,
  totalPages: 0,
  currentPage: 1,
  loading: false,
  error: null,
  redirectTo: false,
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEES_LOADING:
      return handleLoading(state, action);

    case EMPLOYEES_FAILURE:
      return handleFailure(state, action);

    case SET_REDIRECT_URL:
      return handleSetRedirectUrl(state, action);

    case GET_EMPLOYEES:
      return handleGetData(state, action, "employeeList");

    case SET_TOTAL_RECORDS:
      return handleSetTotalRecords(state, action);

    case SET_PAGE:
      return handleSetPage(state, action);

    case EDIT_EMPLOYEE:
      return handleEditItem(state, action, "employeeList", "user_id");

    case ADD_EMPLOYEE:
      return handleAddItem(state, action, "employeeList", "user_id");

    case DELETE_EMPLOYEES:
      return handleDeleteItems(state, action, "employeeList", "user_id");

    default:
      return state;
  }
};
