export const statusOptions = [
  {label: "ACTIVE", value: "ACTIVE"},
  {label: "INACTIVE", value: "INACTIVE"},
  {label: "SUSPENDED", value: "SUSPENDED"}
]

export const ROLE_ACCESS_TYPE = [
  {label: "Editor", value: "EDITOR"},
  {label: "Viewer", value: "VIEWER"}
]

export const LOGIN_COOKIES = {
  AUTHENTICATED: "authenticated",
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  LOGGEDIN_PANEL: "loggedInPanel"
}

export const ALL_PANELS = {
  CORPORATE: "CORPORATE",
  PARTNER: "PARTNER",
  HARMONI: "HARMONI"
}

export const PANELS = {
  CORPORATE: "corporate",
  PARTNER: "partner",
  HARMONI: "harmoni"
}

export const PAGINATION_PER_PAGE = 10

export const SERVICE_CATEGORY = [
  {label: "Fitness", value: "FITNESS"},
  {label: "Sports", value: "SPORTS"},
  {label: "Wellness", value: "WELLNESS"},
  {label: "Medical", value: "MEDICAL"}
]
