import {
  SET_SERVICES_PAGE_DATA,
  SET_SERVICES_TOTAL_RECORDS,
  SET_SERVICES_PAGE,
  EDIT_SERVICES,
  ADD_SERVICES,
  DELETE_SERVICES,
  SERVICES_LOADING,
  SERVICES_FAILURE,
  GET_SERVICES,
  SET_SERVICES_REDIRECT_URL
} from "./services-constant";

import {
  handleLoading,
  handleFailure,
  handleSetRedirectUrl,
  handleGetData,
  handleSetTotalRecords,
  handleSetPage,
  handleEditItem,
  handleAddItem,
  handleDeleteItems,
} from "../utils/reducerUtils";

// Initial state
const initialState = {
  servicesList: {},
  totalRecords: 0,
  totalPages: 0,
  currentPage: 1,
  loading: false,
  error: null,
  redirectTo: false,
};

export const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICES_LOADING:
      return handleLoading(state, action);

    case SERVICES_FAILURE:
      return handleFailure(state, action);

    case SET_SERVICES_REDIRECT_URL:
      return handleSetRedirectUrl(state, action);

    case GET_SERVICES:
      return handleGetData(state, action, "servicesList");

    case SET_SERVICES_TOTAL_RECORDS:
      return handleSetTotalRecords(state, action);

    case SET_SERVICES_PAGE:
      return handleSetPage(state, action);

    case ADD_SERVICES:
      return handleAddItem(state, action, "servicesList", "service_id");

    case EDIT_SERVICES:
      return handleEditItem(state, action, "servicesList", "service_id");

    case DELETE_SERVICES:
      return handleDeleteItems(state, action, "servicesList", "service_id");

    default:
      return state;
  }
};
