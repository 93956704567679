import { deleteCookie, getCookie } from "./cookies";
import { LOGIN_COOKIES } from "../config";
import store from "../redux/store";
import { setAuth } from "../redux/general/general-action";
import { RESET_STORE } from "../redux/general/constants";
// broadcastUtil.js

export const AUTH_CHANNEL = "auth_channel";
const channel = new BroadcastChannel(AUTH_CHANNEL);

export const broadcastLoginSuceess = () => {
  channel.postMessage({ type: "LOGIN" });
};

export const broadcastLogout = () => {
  channel.postMessage({ type: "LOGOUT" });
};

export const listenForAuthMessages = (callback) => {
  channel.onmessage = (event) => {
    callback(event?.data);
  };
};

export const getLogout = () => {
  Object.values(LOGIN_COOKIES).forEach((cookieName) => {
    deleteCookie(cookieName);
  });
  store.dispatch({ type: RESET_STORE });
  broadcastLogout();
};

export const isAuthencationAvailable = () => {
  if (
    !getCookie(LOGIN_COOKIES.REFRESH_TOKEN) ||
    !getCookie(LOGIN_COOKIES.LOGGEDIN_PANEL)
  ) {
    getLogout();
    window.location.reload();
    return false;
  }
  return true;
};
