export const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null;
    return null;
};


interface CookieOptions {
    expires?: Date | string | number; // Expiry can be a Date, string, or number of days
    path?: string;
    domain?: string;
    secure?: boolean;
  }
  
  export function setCookie(name: string, value: string, options: CookieOptions = {}): void {
    let cookieString = `${encodeURIComponent(name)}=${value};`;
  
    // Set the expiration time if provided
    if (options.expires) {
      let expires: string = '';
      if (typeof options.expires === 'number') {
        // Convert days to a UTC string
        const date = new Date();
        date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000);
        expires = date.toUTCString();
      } else if (options.expires instanceof Date) {
        // Use the provided Date object
        expires = options.expires.toUTCString();
      } else {
        // Assume it's already a valid UTC string
        expires = options.expires;
      }
      cookieString += ` expires=${expires};`;
    }
  
    // Set the path if provided
    if (options.path) {
      cookieString += ` path=${options.path};`;
    }
  
    // Set the domain if provided
    if (options.domain) {
      cookieString += ` domain=${options.domain};`;
    }
  
    // Set the secure flag if provided
    if (options.secure) {
      cookieString += ' secure;';
    }
  
    // Set the cookie
    document.cookie = cookieString;
  }


  export function deleteCookie(name:any) {
    // Set the cookie with the same name, an empty value, and an expiration date in the past
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  