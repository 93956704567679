import { SET_LAYOUT_DATA } from "./constants";
import { LayoutAction } from "./action";

export interface LayoutState {
  layoutData: any;
}

const sideMenu = {
  Partner: [
    {
      name: "Dashboard",
      link: "/partner-dashboard",
      icon: "fa fa-dashboard",
    },
    {
      name: "Classes",
      link: "/partner-classes",
      icon: "fa fa-calendar",
      submenu: [
        {
          name: "Schedule Classes",
          link: "/partner-schedule-classes",
          icon: "fa fa-clock",
        },
        {
          name: "Class Reports",
          link: "/partner-class-reports",
          icon: "fa fa-file-alt",
        },
      ],
    },
    {
      name: "Trainers",
      link: "/partner-trainers",
      icon: "fa fa-users",
      submenu: [
        {
          name: "Add Trainer",
          link: "/partner-add-trainer",
          icon: "fa fa-plus",
        },
        {
          name: "Trainer List",
          link: "/partner-trainer-list",
          icon: "fa fa-list",
        },
      ],
    },
    {
      name: "Workout Plans",
      link: "/partner-workout-plans",
      icon: "fa fa-dumbbell",
      submenu: [
        {
          name: "Create Plan",
          link: "/partner-create-plan",
          icon: "fa fa-pencil",
        },
        {
          name: "View Plans",
          link: "/partner-view-plans",
          icon: "fa fa-eye",
        },
      ],
    },
    {
      name: "Nutrition",
      link: "/partner-nutrition",
      icon: "fa fa-apple-alt",
      submenu: [
        {
          name: "Meal Planning",
          link: "/partner-meal-planning",
          icon: "fa fa-cutlery",
        },
        {
          name: "Supplements",
          link: "/partner-supplements",
          icon: "fa fa-medkit",
        },
      ],
    },
    {
      name: "Member Progress",
      link: "/partner-progress",
      icon: "fa fa-chart-line",
      submenu: [
        {
          name: "Progress Tracking",
          link: "/partner-progress-tracking",
          icon: "fa fa-tachometer-alt",
        },
        {
          name: "Goal Setting",
          link: "/partner-goal-setting",
          icon: "fa fa-bullseye",
        },
      ],
    },
    {
      name: "Billing",
      link: "/partner-billing",
      icon: "fa fa-credit-card",
      submenu: [
        {
          name: "Invoices",
          link: "/partner-invoices",
          icon: "fa fa-file-invoice",
        },
        {
          name: "Payments",
          link: "/partner-payments",
          icon: "fa fa-money-bill-wave",
        },
      ],
    },
  ],
  Corporate: [
    {
      name: "Dashboard",
      link: "/corporate/dashboard",
      icon: "fa fa-dashboard",
    },
    {
      name: "Corporate Wellness",
      link: "/wellness",
      icon: "fa fa-heart",
      submenu: [
        {
          name: "Wellness Programs",
          link: "/wellness-programs",
          icon: "fa fa-hiking",
        },
        {
          name: "Health Assessments",
          link: "/health-assessments",
          icon: "fa fa-stethoscope",
        },
      ],
    },
    {
      name: "Corporate Packages",
      link: "/packages",
      icon: "fa fa-box",
      submenu: [
        {
          name: "Package Details",
          link: "/package-details",
          icon: "fa fa-info-circle",
        },
        {
          name: "Package Pricing",
          link: "/package-pricing",
          icon: "fa fa-dollar-sign",
        },
      ],
    },
    {
      name: "Events",
      link: "/events",
      icon: "fa fa-calendar-alt",
      submenu: [
        {
          name: "Event Calendar",
          link: "/event-calendar",
          icon: "fa fa-calendar-week",
        },
        {
          name: "Event Registration",
          link: "/event-registration",
          icon: "fa fa-ticket-alt",
        },
      ],
    },
    {
      name: "Employee Programs",
      link: "/employee-programs",
      icon: "fa fa-briefcase",
      submenu: [
        {
          name: "Program Overview",
          link: "/program-overview",
          icon: "fa fa-book",
        },
        {
          name: "Program Analytics",
          link: "/program-analytics",
          icon: "fa fa-chart-bar",
        },
      ],
    },
    {
      name: "Reports",
      link: "/reports",
      icon: "fa fa-flag",
      submenu: [
        {
          name: "Sales Reports",
          link: "/sales-reports",
          icon: "fa fa-chart-line",
        },
        {
          name: "Attendance Reports",
          link: "/attendance-reports",
          icon: "fa fa-user-check",
        },
      ],
    },
    {
      name: "Feedback",
      link: "/feedback",
      icon: "fa fa-comments",
      submenu: [
        {
          name: "Submit Feedback",
          link: "/submit-feedback",
          icon: "fa fa-comment",
        },
        {
          name: "View Feedback",
          link: "/view-feedback",
          icon: "fa fa-eye",
        },
      ],
    },
  ],
  Admin: [
    {
      name: "Dashboard",
      link: "/harmoni/dashboard",
      icon: "DashboardIcon",
    },
    {
      name: "Employees",
      link: "/harmoni/employee",
      icon: "Groups2Icon",
    },
    {
      name: "Service Management",
      link: "/harmoni/service-management",
      icon: "Services",
    },
    {
      name: "Amenities Management",
      link: "/harmoni/amenities-management",
      icon: "Amenities",
    },
    {
      "name": "Users",
      "link": "/harmoni/users",
      "icon": "Person2Icon"
    },
    {
      "name": "Corporate",
      "link": "/harmoni/corporate",
      "icon": "ApartmentIcon"
    },
    {
      "name": "Partner",
      "link": "/harmoni/partner",
      "icon": "HandshakeIcon"
    },

    {
      "name":"Partner Services",
      "icon":"ManageAccountsIcon",
      "submenu":[

        {
          "name": "Manage subscriptions",
          "link": "/harmoni/manage-subscription",
          "icon": "fa fa-money-bill-wave"
        },

      ]
    },
    {
      "name": "Financial Management",
      "link": "/harmoni/financial-management",
      "icon": "CurrencyRupeeIcon"
    },
    {
      "name": "Booking Management",
      "link": "/harmoni/booking-management",
      "icon": "MenuBookIcon"
    },
    {
      "name": "Role Management",
      "link": "/harmoni/role-management",
      "icon": "SupervisorAccountIcon"
    },
    {
      "name": "Reporting and Analytics",
      "link": "/harmoni/reporting-analytics",
      "icon": "AnalyticsIcon"
    },
    {
      "name": "Profile",
      "link": "/harmoni/profile",
      "icon": "AccountCircleIcon"
    },
    {
      "name": "Settings",
      "link": "/harmoni/settings",
      "icon": "SettingsIcon"
    }
  ],
};

const initialState: LayoutState = {
  layoutData: sideMenu,
};

const layoutReducer = (
  state = initialState,
  action: LayoutAction
): LayoutState => {
  switch (action.type) {
    case SET_LAYOUT_DATA:
      return {
        ...state,
        layoutData: action.payload,
      };
    default:
      return state;
  }
};

export default layoutReducer;
